import React, { ReactNode, createContext, useContext, useRef, useState } from "react";

// Create a context with default values
const AppContext = createContext({
  dangerMode: false,
  token: "",
  btCharacteristicRef: null as any,
  setDangerMode: (v: boolean) => {},
  setToken: (s: string) => {},
});

// Create a context provider component
export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dangerMode, setDangerMode] = useState(false);
  const [token, setToken] = useState("");
  const btCharacteristicRef = useRef<any>(null);

  // Value object to be provided by the context
  const contextValue = {
    dangerMode,
    token,
    btCharacteristicRef,
    setDangerMode,
    setToken,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

// Custom hook to consume the context
export const useAppContext = () => useContext(AppContext);
