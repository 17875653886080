import React, { useEffect, useRef } from "react";
import useWebsocket from "./useWebsocket";
import TouchContainer from "./TouchContainer";

const ManualPage: React.FC = () => {
  const { sendPattern } = useWebsocket();

  const currentData = useRef<number>(0);

  useEffect(() => {
    let lastData = 0;
    const handleFrame = () => {
      if (currentData.current !== lastData) {
        lastData = currentData.current;
        sendPattern([currentData.current]);
        console.log(currentData.current);
      }
    };
    const timerId = setInterval(handleFrame, 100);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const handleMouseMove = ({ x, y }: { x: number; y: number }) => {
    currentData.current = y;
  };

  return (
    <div>
      <TouchContainer
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseMove}
        onMouseUp={() => {}}
      />
    </div>
  );
};

export default ManualPage;
