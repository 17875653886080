import React, { useState } from "react";

interface TouchContainerProps {
  onMouseMove: (position: { x: number; y: number; timeStamp: number }) => void;
  onMouseDown: (position: { x: number; y: number; timeStamp: number }) => void;
  onMouseUp: (position: { timeStamp: number }) => void;
}

const TouchContainer: React.FC<TouchContainerProps> = ({
  onMouseMove,
  onMouseDown,
  onMouseUp,
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const containerRef = React.createRef<HTMLDivElement>();

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onMouseMove && isClicked) {
      onMouseMove(calculatePercentagePosition(e));
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onMouseDown) {
      setIsClicked(true);
      onMouseDown(calculatePercentagePosition(e));
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onMouseUp) {
      setIsClicked(false);
      onMouseUp(e);
    }
  };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    const mouseEvent = {
      ...e,
      clientX: touch.clientX,
      clientY: touch.clientY,
      offsetX: touch.pageX - e.currentTarget.getBoundingClientRect().left,
      offsetY: touch.pageY - e.currentTarget.getBoundingClientRect().top,
    } as any as React.MouseEvent<HTMLDivElement>;
    handleMouseMove(mouseEvent);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    const mouseEvent = {
      ...e,
      clientX: touch.clientX,
      clientY: touch.clientY,
      offsetX: touch.pageX - e.currentTarget.getBoundingClientRect().left,
      offsetY: touch.pageY - e.currentTarget.getBoundingClientRect().top,
    } as any as React.MouseEvent<HTMLDivElement>;
    handleMouseDown(mouseEvent);
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    handleMouseUp(e as any as React.MouseEvent<HTMLDivElement>);
  };

  const calculatePercentagePosition = (event: {
    clientX: number;
    clientY: number;
    timeStamp: number;
  }) => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (!containerRect) {
      return { x: 0, y: 0, timeStamp: event.timeStamp };
    }

    const xPercentage =
      ((event.clientX - containerRect.left) / containerRect.width) * 100;
    const yPercentage =
      ((containerRect.bottom - event.clientY) / containerRect.height) * 100;

    return { x: xPercentage, y: yPercentage, timeStamp: event.timeStamp };
  };

  const gradientStyle: React.CSSProperties = {
    width: "100%",
    height: "60vh",
    background:
      "linear-gradient(0deg, rgba(4,0,64,1) -20%, rgba(0,26,66,1) 20%, rgba(233,0,255,1) 105%)",
    margin: "10% 0 0 0",
    touchAction: "none",
  };

  return (
    <div
      ref={containerRef}
      style={gradientStyle}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    ></div>
  );
};

export default TouchContainer;
