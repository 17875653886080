import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useSharedWebSocket from "./useWebsocket";
import { useAppContext } from "./Context";
import VerticalLoadingBar from "./VerticalLoadingBar";

interface ButtonProps {
  to: string;
  currentPage: string;
  onClick?: () => void;
  children: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  to,
  currentPage,
  onClick,
  children,
}) => {
  const isSelected = currentPage === to;

  return (
    <Link
      to={to}
      style={{
        width: "100%",
        backgroundColor: isSelected ? "rgba(233,0,255,1)" : "rgba(0,26,66,1)",
        color: isSelected ? "rgba(0,26,66,1)" : "rgba(233,0,255,1)",
        textAlign: "center",
        padding: "1em 0",
        userSelect: "none",
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

interface MenuProps {
  currentPage: string;
}

const Menu: React.FC<MenuProps> = ({ currentPage }) => {
  const [clients, setClients] = useState<{
    [id: string]: { id: string; power: number; time: number };
  }>({});
  const { btCharacteristicRef } = useAppContext();
  const { message } = useSharedWebSocket();

  const btAvailable = !!(navigator as any)?.bluetooth.getAvailability();

  useEffect(() => {
    console.log("Started interval");
    const interval = setInterval(async () => {
      if (!btCharacteristicRef.current) {
        return;
      }
      try {
        const value = await btCharacteristicRef.current.readValue();
        const str = new TextDecoder().decode(value);
        const msg = JSON.parse(str)?.msg;
        const json = JSON.parse(msg);
        console.log("Got ", json);
        if ("id" in json) {
          setClients((c) => ({
            ...c,
            [json.id]: { time: Date.now(), ...json },
          }));
        }
      } catch (err) {
        console.error("unable to read characteristic", err);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [btCharacteristicRef.current]);

  useEffect(() => {
    if ("id" in message) {
      setClients((c) => ({
        ...c,
        [message.id]: { time: Date.now(), ...message },
      }));
    }
  }, [message]);

  useEffect(() => {
    const interval = setInterval(() => {
      setClients((c) =>
        Object.values(c)
          .filter((v) => v.time > Date.now() - 5000)
          .reduce((acc, cur) => ({ acc, [cur.id]: cur }), {})
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ display: "grid", columnCount: 2 }}>
      <div
        style={{ display: "inline-block", maxWidth: "8em", overflow: "hidden" }}
      >
        {Object.values(clients).map(
          (c) =>
            c.id && <VerticalLoadingBar percentage={c.power / 255} key={c.id} />
        )}
        {Object.values(clients).length === 0 && <p>No clients connected</p>}
      </div>
      <div
        style={{
          display: "grid",
          gridAutoFlow: "column",
          gridColumnStart: "none",
        }}
      >
        <Button to="/" currentPage={currentPage}>
          Manual
        </Button>
        <Button to="/audio" currentPage={currentPage}>
          Audio
        </Button>
        <Button to="/pattern" currentPage={currentPage}>
          Pattern
        </Button>
        <Button to="/random" currentPage={currentPage}>
          Random
        </Button>
        {btAvailable && (
          <button
            onClick={() =>
              btCharacteristicRef.current
                ? (btCharacteristicRef.current = null)
                : (navigator as any).bluetooth
                  .requestDevice({
                    filters: [
                      { services: ["4fafc201-1fb5-459e-8fcc-c5c9c331914b"] },
                    ],
                  })
                  .then((device: any) => device.gatt.connect())
                  .then((server: any) => {
                    return server.getPrimaryService(
                      "4fafc201-1fb5-459e-8fcc-c5c9c331914b"
                    );
                  })
                  .then((service: any) => {
                    // Getting Battery Level Characteristic…
                    return service.getCharacteristic(
                      "beb5483e-36e1-4688-b7f5-ea07361b26a8"
                    );
                  })
                  .then((characteristic: any) => {
                    btCharacteristicRef.current = characteristic;
                  })
                  .catch((error: any) => {
                    console.error(error);
                  })
            }
          >
            {!btCharacteristicRef.current ? "BT" : "Using BT"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Menu;
