import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LoadingScreen: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    background:
      "linear-gradient(90deg, rgba(4,0,64,1) 0%, rgba(0,26,66,1) 46%, rgba(233,0,255,1) 100%)",
  };

  const h2Style: React.CSSProperties = {
    backgroundColor: "rgba(233,0,255,1)",
    color: "white",
    padding: "1em 2em",
    fontSize: "1.5em",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <h2 style={h2Style}>Loading</h2>
    </div>
  );
};

export default LoadingScreen;
