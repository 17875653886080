import React, { useEffect, useRef, useState } from "react";
import useSharedWebSocket from "./useWebsocket";
import Chart from "chart.js/auto";
import MultiRangeSlider from "multi-range-slider-react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const AudioPage: React.FC = () => {
  const { sendPattern } = useSharedWebSocket();

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart | null>(null);
  const [chartData, setChartData] = useState<{ x: number; y: number }[]>([]);

  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);
  const [sensitivity, set_sensitivity] = useState(1);

  useEffect(() => {
    let currentData = 0;
    let audioContext: AudioContext | null = null;
    let thisFrame: number[] = [];
    let time = 0;

    const handleFrame = () => {
      const average = Math.round(
        (100 * thisFrame.reduce((sum, number) => sum + number, 0)) /
          thisFrame.length
      );
      thisFrame = [];
      const scaledValue = Math.min(Math.max(average * sensitivity, 0), 100);
      const mappedValue =
        minValue + Math.min(((maxValue - minValue) * scaledValue) / 100);

      if (currentData !== mappedValue) {
        currentData = mappedValue;
        sendPattern([mappedValue]);
      }
      setChartData((chartData) =>
        [...chartData, { y: mappedValue, x: time }].slice(-20)
      );
      time += 1;
    };
    const timerId = setInterval(handleFrame, 250);

    const startAudioProcessing = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        audioContext = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);

        scriptProcessor.onaudioprocess = function () {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;

          const percent = Math.min(average / 50, 1);

          thisFrame.push(percent);
        };
      } catch (err) {
        console.error(err);
        // Handle the error appropriately (e.g., display an error message)
      }
    };

    startAudioProcessing(); // Start audio processing when the component is mounted

    return () => {
      clearInterval(timerId);
      // Cleanup logic: Stop the audio processing and release resources when the component is unmounted
      if (audioContext) {
        audioContext
          .close()
          .then(() => {
            console.log("AudioContext closed.");
          })
          .catch((error) => {
            console.error("Error closing AudioContext:", error);
          });
      }
    };
  }, [minValue, maxValue, sensitivity]);

  useEffect(() => {
    if (chart) {
      chart.data.labels = chartData.map((d) => d.x);
      chart.data.datasets[0].data = chartData.map((d) => d.y);
      chart.update("none");
    }
  }, [chart, chartData]);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const newChart = new Chart(ctx, {
          type: "line",
          data: {
            labels: Array.from(
              { length: chartData.length },
              (_, index) => index + 1
            ),
            datasets: [
              {
                label: "Last 20 Values",
                data: chartData,
                borderColor: "rgba(233,0,255,1)",
                backgroundColor: "rgba(233,0,255,0.2)",
                borderWidth: 2,
                pointRadius: 0,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                display: false,
              },
              y: {
                suggestedMin: 0,
                suggestedMax: 100,
              },
            },
          },
        });

        setChart(newChart);
        return () => {
          newChart.destroy();
        };
      }
    }
  }, [chartRef.current]);

  return (
    <div style={{ height: "50vh" }}>
      <br></br>
      <MultiRangeSlider
        style={{
          width: "80%",
          margin: "8px auto",
          backgroundColor: "rgba(0,26,66,1)",
          textAlign: "center",
          padding: "1em 1em",
          border: "none",
          boxShadow: "none",
        }}
        min={0}
        max={100}
        step={1}
        minValue={minValue}
        maxValue={maxValue}
        canMinMaxValueSame={true}
        label="false"
        ruler="false"
        barInnerColor="rgba(233,0,255,1)"
        onInput={(e) => {
          set_minValue(e.minValue);
          set_maxValue(e.maxValue);
        }}
      />
      <canvas ref={chartRef} width={400} height={400}></canvas>
      <div
        style={{
          width: "80%",
          margin: "0 auto",
          textAlign: "center",
          padding: "1em 1em",
        }}
      >
        <Slider
          min={0.1}
          max={10}
          step={0.1}
          value={sensitivity}
          onChange={(v) => {
            set_sensitivity(v as number);
          }}
          styles={{
            track: {
              height: 10,
              backgroundColor: "rgba(233,0,255,1)",
            },
            handle: {
              height: 28,
              width: 28,
              marginLeft: -14,
              marginTop: -9,
            },
            rail: {
              height: 10,
            },
          }}
        />
      </div>
    </div>
  );
};

export default AudioPage;
