import React, { useEffect, useRef, useState } from "react";
import useSharedWebSocket from "./useWebsocket";
import Chart from "chart.js/auto";
import MultiRangeSlider from "multi-range-slider-react";

const RandomPage: React.FC = () => {
  const { sendPattern } = useSharedWebSocket();

  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [chart, setChart] = useState<Chart | null>(null);
  const [chartData, setChartData] = useState<{ x: number; y: number }[]>([]);

  const [minValue, set_minValue] = useState(25);
  const [maxValue, set_maxValue] = useState(75);

  const generateRandom = () => {
    const values: number[] = [];
    const maxRandomValue = maxValue - minValue;
    const maxLength = 5_000;

    for (let i = 0; i < 30; i++) {
      values.push(
        Math.round(minValue + Math.random() * maxRandomValue),
        Math.round(Math.random() * maxLength)
      );
    }

    sendPattern(values);

    const chartData: { x: number; y: number }[] = [];
    chartData.push({
      y: values[0],
      x: 0,
    });
    let accTime = 0;
    for (let i = 0; i < values.length; i += 2) {
      const prePoint = {
        y: values[i],
        x: accTime,
      };
      chartData.push(prePoint);
      accTime += values[i + 1];
      const point = {
        y: values[i],
        x: accTime,
      };
      chartData.push(point);
    }
    setChartData(chartData);
  };

  useEffect(() => {
    if (chart) {
      chart.data.labels = chartData.map((d) => d.x);
      chart.data.datasets[0].data = chartData.map((d) => d.y);
      chart.update("none");
      console.log(chartData);
    }
  }, [chart, chartData]);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        const newChart = new Chart(ctx, {
          type: "line",
          data: {
            labels: Array.from(
              { length: chartData.length },
              (_, index) => index + 1
            ),
            datasets: [
              {
                label: "Last 20 Values",
                data: chartData,
                borderColor: "rgba(233,0,255,1)",
                backgroundColor: "rgba(233,0,255,0.2)",
                borderWidth: 2,
                pointRadius: 0,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                display: false,
              },
              y: {
                suggestedMin: 0,
                suggestedMax: 100,
              },
            },
          },
        });

        setChart(newChart);
        return () => {
          newChart.destroy();
        };
      }
    }
  }, [chartRef.current]);

  return (
    <div style={{ height: "50vh" }}>
      <MultiRangeSlider
        style={{
          width: "80%",
          margin: '8px auto',
          backgroundColor: "rgba(0,26,66,1)",
          textAlign: "center",
          padding: "1em 1em",
          border: 'none',
          boxShadow: 'none',
        }}
        min={0}
        max={100}
        step={1}
        minValue={minValue}
        maxValue={maxValue}
        canMinMaxValueSame={true}
        label='false'
        ruler='false'
        barInnerColor='rgba(233,0,255,1)'
        onInput={(e) => {
          set_minValue(e.minValue);
          set_maxValue(e.maxValue);
        }}
      />
      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(0,26,66,1)",
          color: "rgba(233,0,255,1)",
          textAlign: "center",
          padding: "1em 0",
          userSelect: "none",
        }}
        onClick={generateRandom}
        id="randomButton"
      >
        Generate random
      </div>

      <canvas ref={chartRef} width={400} height={400}></canvas>
    </div>
  );
};

export default RandomPage;
