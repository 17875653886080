import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { useAppContext } from "./Context";
import { useEffect } from "react";

const useSharedWebSocket = () => {
  const { btCharacteristicRef } = useAppContext();
  const { token } = useAppContext();
  const { lastMessage, readyState, sendJsonMessage, sendMessage } =
    useWebSocket("wss://ledlights.mashaogthomas.no/ws", {
      protocols: [token],
      shouldReconnect: () => true,
      reconnectAttempts: 99999,
      reconnectInterval: 1000,
      share: true,
      onError: console.error,
    });

  const send = (data: Record<string, any>) => {
    if (btCharacteristicRef.current) {
      btCharacteristicRef.current.writeValue(new TextEncoder().encode(data.msg));
    } else {
      sendJsonMessage(data);
    }
  };
  const sendPattern = (values: number[]) => {
    send({
      program: "goodvibes",
      msg: values.map((v, i) => i % 2 === 0 ? Math.round((v * 255) / 100) : v).join(" "),
    });
  };

  useEffect(() => {
    if (lastMessage?.data === "ping") {
      sendMessage("pong");
    }
  }, [lastMessage]);

  const message =
    lastMessage?.data === "ping"
      ? {}
      : JSON.parse((lastMessage?.data as null | string) || "{}");

  return { send, sendPattern, message, readyState };
};

export default useSharedWebSocket;
