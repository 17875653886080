import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Menu from "./Menu";
import AudioPage from "./AudioPage";
import PatternPage from "./Pattern";
import ManualPage from "./ManualPage";
import { useAuth0 } from "@auth0/auth0-react";
import LoginScreen from "./LoginScreen";
import { useAppContext } from "./Context";
import LoadingScreen from "./LoadingScreen";
import RandomPage from "./Random";

const App: React.FC = () => {
  const { token, setToken } = useAppContext();
  const location = useLocation();
  const currentPage = location.pathname;
  useEffect(() => {
    let screenLock;
    function isScreenLockSupported() {
      return "wakeLock" in window.navigator;
    }
    if (isScreenLockSupported()) {
      (window.navigator as any).wakeLock.request("screen").then((lock: any) => {
        screenLock = lock;
      });
    }
  }, []);

  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const updateToken = () => {
      getAccessTokenSilently().then((token) => {
        setToken(token);
      });
    };
    const interval = setInterval(() => {
      updateToken();
    }, 30 * 60 * 1000);
    updateToken();

    return () => clearInterval(interval);
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <LoginScreen />;
  }
  if (!token) {
    return <LoadingScreen />;
  }

  return (
    <div
      style={{
        height: "100dvh",
        width: "100dvw",
        backgroundColor: "rgb(0, 0, 0)",
        color: "rgb(143, 148, 148)",
        margin: 0,
        padding: 0,
        overflow: "none",
        display: "grid",
        gridTemplateRows: "50px 50px auto",
      }}
    >
      <Menu currentPage={currentPage} />
      <Routes>
        <Route path="/" Component={ManualPage} />
        <Route path="/audio" Component={AudioPage} />
        <Route path="/pattern" Component={PatternPage} />
        <Route path="/random" Component={RandomPage} />
      </Routes>
    </div>
  );
};

export default App;
