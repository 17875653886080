import React, { useState, useEffect, useRef } from "react";
import useWebsocket from "./useWebsocket";
import TouchContainer from "./TouchContainer";

const PatternPage: React.FC = () => {
  const { sendPattern } = useWebsocket();
  const [isRecording, setRecording] = useState<boolean>(false);
  const [recordedEvents, setRecordedEvents] = useState<
    { x: number; y: number; time: number }[]
  >([]);
  const currentData = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (!isRecording) {
      return;
    }
    let lastData = 0;
    const handleFrame = () => {
      if (currentData.current !== undefined && currentData.current !== lastData) {
        lastData = currentData.current;
        sendPattern([currentData.current]);
        console.log(currentData.current);
      }
    };
    const timerId = setInterval(handleFrame, 100);

    return () => {
      clearInterval(timerId);
    };
  }, [isRecording]);

  const handleMouseMove = ({
    x,
    y,
    timeStamp,
  }: {
    x: number;
    y: number;
    timeStamp: number;
  }) => {
    if (isRecording) {
      currentData.current = y;
      console.log("Move", y, x, timeStamp);
      // Only add the event if the current x value is different from the previous event
      setRecordedEvents((prevEvents) => {
        const prevEvent = prevEvents.slice(-1)[0];
        if (!prevEvent) {
          return [{ x, y, time: timeStamp || 0 }];
        }
        const time = (timeStamp || 0) - prevEvent.time;
        if (Math.abs(y - prevEvent.y) > 2 && time > 50) {
          return [...prevEvents, { x, y, time: timeStamp || 0 }];
        }
        return prevEvents;
      });
    }
  };

  const handleMouseUp = ({
    timeStamp,
  }: {
    timeStamp: number;
  }) => {
    console.log("UP");
    if (isRecording) {
      // Only add the event if the current x value is different from the previous event
      setRecordedEvents((prevEvents) => {
        const prevEvent = prevEvents.slice(-1)[0];
        if (!prevEvent) {
          return prevEvents;
        }
        if (currentData.current !== 0) {
          return [...prevEvents, { x: 0, y: 0, time: timeStamp || 0 }];
        }
        return prevEvents;
      });
    }
  };

  const handleRecordToggle = () => {
    if (isRecording) {
      // Stop recording and send the pattern
      setRecording(false);

      if (recordedEvents.length > 0) {
        const pattern = recordedEvents
          .map((event, i, arr) => ({
            ...event,
            time: arr[i].time - (arr[i - 1]?.time || 0),
          }))
          .map((event) => [event.time, event.y])
          .flat()
          .slice(1, -1);
        console.log(recordedEvents, pattern);
        sendPattern(pattern);
        setRecordedEvents([]);
        currentData.current = undefined;
      }

      // Clear recorded events
      setRecordedEvents([]);
    } else {
      // Start recording
      setRecording(true);
    }
  };

  useEffect(() => {
    console.log(recordedEvents);
  }, [recordedEvents])

  return (
    <div>

      <div
        style={{
          width: "100%",
          backgroundColor: "rgba(0,26,66,1)",
          color: "rgba(233,0,255,1)",
          textAlign: "center",
          padding: "1em 0",
          marginTop: "2em",
          userSelect: "none",
        }}
        onClick={handleRecordToggle}
      >
        {isRecording ? "Stop Recording" : "Start Recording"}
      </div>
      <TouchContainer
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseMove}
        onMouseUp={handleMouseUp}
      />
    </div>
  );
};

export default PatternPage;
