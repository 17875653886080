import React from "react";
import "./VerticalLoading.css";

const VerticalLoadingBar: React.FC<{ percentage: number }> = ({
  percentage,
}: {
  percentage: number;
}) => {
  return (
    <div className="vlbarcontainer">
      <div
        className="vlbar"
        style={{ height: Math.round(percentage*100) + "%" }}
      ></div>
    </div>
  );
};

export default VerticalLoadingBar;
