import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppProvider } from "./Context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <Auth0Provider
        domain="mashaogthomas.eu.auth0.com"
        clientId="WaScBj2g5J7KlO3vr9kU9XAzXhfogWm1"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://iot.mashaogthomas.no",
        }}
        cacheLocation="localstorage"
      >
        <AppProvider>
          <App />
        </AppProvider>
      </Auth0Provider>
    </HashRouter>
  </React.StrictMode>
);
